ul.react-paginate {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 5rem;
  }
  ul.react-paginate li {
    margin: 0;
  }
  .table {
    border-collapse: separate;
    border-spacing:5px 5px;
}
  tr:hover{
   background-color:  rgba(25, 118, 210, 0.12); /* Change this to the desired background color on hover */
      color: #fff;

      cursor:pointer;
  }

  ul.react-paginate li a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 4px;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #333;

    cursor: pointer;
  }
  ul.react-paginate li a:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }

  td.danger{
    background-color:  rgba(210, 25, 25, 0.12); /* Change this to the desired background color on hover */
  }
  td.warning{
    background-color:  rgba(210, 148, 25, 0.12); /* Change this to the desired background color on hover */
  }


  ul.react-paginate li.previous a,
  ul.react-paginate li.next a,
  ul.react-paginate li.break a {
    border: 0;
    border-radius: 0;
    background: none;
    width: 75px;
    cursor: pointer;
  }

  ul.react-paginate li.selected a {
   border: 1px solid rgba(25, 118, 210, 0.5);
   background-color: rgba(25, 118, 210, 0.12);
   color: #1976d2;
  }

  ul.react-paginate li.disabled a {
    color: grey;
  }

  ul.react-paginate li.disable,
  ul.react-paginate li.disabled a {
    color: #ccc;
    pointer-events: none;
  }

  /* Additional styles for dark Material-UI pagination */
  ul.react-paginate.dark-material {
    background-color: #333;
    color: white;
  }

  ul.react-paginate.dark-material li a {
    border: 1px solid #555;
  }

  ul.react-paginate.dark-material li.selected a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
  }

  ul.react-paginate.dark-material li.disabled a {
    color: #666;
  }


  /* starts here */













